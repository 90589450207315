function LogoBlack() {
    return (
        <svg width="125" height="27" viewBox="0 0 125 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.151 27C18.8619 27 24.3021 20.956 24.3021 13.5003C24.3021 6.04454 18.8619 0.000488281 12.151 0.000488281C5.4402 0.000488281 0 6.04454 0 13.5003C0 20.956 5.4402 27 12.151 27Z" fill="#77D5EF"/>
            <path d="M32.1186 26.9995C38.8288 26.9995 44.2696 20.9548 44.2696 13.4998C44.2696 6.04473 38.8288 0 32.1186 0H24.3022V26.9995H32.1186Z" fill="#00E0A3"/>
            <path d="M47.0126 13.4749C47.0126 11.3431 47.5684 9.60263 48.68 8.25551C49.7917 6.90838 51.2133 6.23584 52.9487 6.23584C54.6822 6.23584 56.1057 6.91042 57.2173 8.25551C58.329 9.60263 58.8848 11.3411 58.8848 13.4749C58.8848 15.5883 58.329 17.3287 57.2173 18.6942C56.1057 20.0597 54.6822 20.7424 52.9487 20.7424C51.2152 20.7424 49.7917 20.0597 48.68 18.6942C47.5684 17.3287 47.0126 15.5883 47.0126 13.4749ZM54.8546 16.3933C55.3169 15.7004 55.5498 14.7384 55.5498 13.5034C55.5498 12.2683 55.3132 11.3003 54.8418 10.5992C54.3703 9.89814 53.7393 9.54761 52.9468 9.54761C52.1379 9.54761 51.5032 9.89814 51.0409 10.5992C50.5768 11.3003 50.3457 12.2683 50.3457 13.5034C50.3457 14.7384 50.5768 15.7024 51.0409 16.3933C51.5032 17.0862 52.1397 17.4306 52.9468 17.4306C53.7558 17.4306 54.3905 17.0862 54.8546 16.3933Z" fill="#262626"/>
            <path d="M70.9777 11.3716V20.7424H67.7198V11.82C67.7198 11.1271 67.5345 10.5707 67.164 10.1509C66.7934 9.72899 66.3128 9.51908 65.724 9.51908C65.1351 9.51908 64.6508 9.73511 64.2711 10.1651C63.8914 10.5951 63.7025 11.1474 63.7025 11.82V20.7424H60.4446V6.54358H63.4933V7.69506C64.3188 6.72292 65.3369 6.23584 66.6834 6.23584C67.9968 6.23584 69.0405 6.70458 69.8147 7.63799C70.5906 8.57548 70.9777 9.81866 70.9777 11.3716Z" fill="#262626"/>
            <path d="M80.9048 0.0117188H84.1627V20.7423H81.1818V18.8613C80.4242 20.133 79.2704 20.7688 77.7221 20.7688C76.139 20.7688 74.8641 20.1045 73.8956 18.7777C72.927 17.4489 72.4427 15.6922 72.4427 13.5033C72.4427 11.3145 72.927 9.55776 73.8956 8.22897C74.8641 6.90019 76.139 6.23579 77.7221 6.23579C79.0851 6.23579 80.1472 6.73103 80.9048 7.72354V0.0117188ZM80.2609 16.4503C80.7232 15.7778 80.9562 14.7954 80.9562 13.5033C80.9562 12.2133 80.7195 11.2269 80.2481 10.5441C79.7767 9.86142 79.153 9.51903 78.3788 9.51903C77.5864 9.51903 76.9554 9.86549 76.4839 10.5564C76.0125 11.2493 75.7758 12.2214 75.7758 13.4748C75.7758 14.7465 76.007 15.7288 76.4711 16.4218C76.9333 17.1147 77.5699 17.4591 78.377 17.4591C79.1695 17.4591 79.7968 17.1228 80.2609 16.4503Z" fill="#262626"/>
            <path d="M96.6126 14.7388H88.9852C89.3044 16.6647 90.2894 17.6287 91.9404 17.6287C92.9842 17.6287 93.9527 17.162 94.8442 16.2266L96.4108 18.5275C95.1653 20.0234 93.6409 20.7713 91.8395 20.7713C90.0033 20.7713 88.5266 20.0988 87.4076 18.7517C86.2868 17.4045 85.7273 15.6478 85.7273 13.4773C85.7273 11.3455 86.2739 9.60507 87.3691 8.25795C88.4624 6.91082 89.8528 6.23828 91.5368 6.23828C93.1364 6.23828 94.415 6.82319 95.3762 7.99301C96.3356 9.16283 96.8162 10.7015 96.8162 12.6091C96.8162 13.3163 96.7483 14.0275 96.6126 14.7388ZM88.9613 12.1567H93.7088C93.6922 11.2212 93.4813 10.4916 93.0777 9.96784C92.6742 9.44407 92.1348 9.18117 91.4616 9.18117C90.8049 9.18117 90.2527 9.44407 89.807 9.96784C89.3612 10.4916 89.0787 11.2212 88.9613 12.1567Z" fill="#262626"/>
            <path d="M105.553 6.54395H109.013L103.482 20.7713H102.195L96.6639 6.54395H100.124L102.851 14.3169L105.553 6.54395Z" fill="#262626"/>
            <path d="M111.541 4.30589C112.1 4.30589 112.573 4.10209 112.957 3.69245C113.34 3.28281 113.533 2.77739 113.533 2.1721C113.533 1.56681 113.34 1.05527 112.957 0.637476C112.573 0.219684 112.1 0.00976562 111.541 0.00976562C110.996 0.00976562 110.532 0.219684 110.148 0.637476C109.765 1.05527 109.573 1.56681 109.573 2.1721C109.573 2.77739 109.765 3.28485 110.148 3.69245C110.532 4.10209 110.996 4.30589 111.541 4.30589Z" fill="#262626"/>
            <path d="M113.181 6.54395H109.923V20.7713H113.181V6.54395Z" fill="#262626"/>
            <path d="M124.999 11.7368V20.7734H122.019V19.2C121.177 20.2475 120.039 20.7713 118.608 20.7713C117.412 20.7713 116.453 20.3922 115.73 19.6341C115.006 18.876 114.644 17.8896 114.644 16.6729C114.644 15.4195 115.039 14.429 115.831 13.6994C116.622 12.9698 117.7 12.605 119.063 12.605H121.74V11.6798C121.74 10.9685 121.567 10.4162 121.222 10.0249C120.878 9.63157 120.377 9.43592 119.72 9.43592C118.726 9.43592 117.766 10.0432 116.842 11.2599L115.024 9.12818C116.286 7.20226 117.97 6.23828 120.076 6.23828C121.608 6.23828 122.811 6.71518 123.688 7.66897C124.561 8.62072 124.999 9.97803 124.999 11.7368ZM121.742 15.6355V15.018H119.57C118.474 15.018 117.928 15.5133 117.928 16.5058C117.928 16.9929 118.075 17.3719 118.37 17.643C118.663 17.914 119.047 18.0506 119.52 18.0506C120.16 18.0506 120.69 17.8264 121.111 17.378C121.531 16.9276 121.742 16.3468 121.742 15.6355Z" fill="#262626"/>
        </svg>
    );
}


function LogoWhite() {
    return (
        <svg width="125" height="27" viewBox="0 0 125 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.151 27C18.8619 27 24.3021 20.956 24.3021 13.5003C24.3021 6.04454 18.8619 0.000488281 12.151 0.000488281C5.4402 0.000488281 0 6.04454 0 13.5003C0 20.956 5.4402 27 12.151 27Z" fill="#77D5EF"/>
            <path d="M32.1186 26.9995C38.8289 26.9995 44.2697 20.9548 44.2697 13.4998C44.2697 6.04473 38.8289 0 32.1186 0H24.3023V26.9995H32.1186Z" fill="#00E0A3"/>
            <path d="M47.0126 13.4749C47.0126 11.3431 47.5684 9.60263 48.6801 8.25551C49.7917 6.90838 51.2134 6.23584 52.9487 6.23584C54.6822 6.23584 56.1057 6.91042 57.2173 8.25551C58.329 9.60263 58.8848 11.3411 58.8848 13.4749C58.8848 15.5883 58.329 17.3287 57.2173 18.6942C56.1057 20.0597 54.6822 20.7424 52.9487 20.7424C51.2152 20.7424 49.7917 20.0597 48.6801 18.6942C47.5684 17.3287 47.0126 15.5883 47.0126 13.4749ZM54.8546 16.3933C55.3169 15.7004 55.5499 14.7384 55.5499 13.5034C55.5499 12.2683 55.3132 11.3003 54.8418 10.5992C54.3703 9.89814 53.7393 9.54761 52.9469 9.54761C52.1379 9.54761 51.5032 9.89814 51.0409 10.5992C50.5768 11.3003 50.3457 12.2683 50.3457 13.5034C50.3457 14.7384 50.5768 15.7024 51.0409 16.3933C51.5032 17.0862 52.1397 17.4306 52.9469 17.4306C53.7558 17.4306 54.3905 17.0862 54.8546 16.3933Z" fill="#FDFDFD"/>
            <path d="M70.9777 11.3716V20.7424H67.7198V11.82C67.7198 11.1271 67.5345 10.5707 67.164 10.1509C66.7934 9.72899 66.3128 9.51908 65.724 9.51908C65.1352 9.51908 64.6509 9.73511 64.2712 10.1651C63.8914 10.5951 63.7025 11.1474 63.7025 11.82V20.7424H60.4446V6.54358H63.4934V7.69506C64.3189 6.72292 65.3369 6.23584 66.6834 6.23584C67.9968 6.23584 69.0406 6.70458 69.8147 7.63799C70.5906 8.57548 70.9777 9.81866 70.9777 11.3716Z" fill="#FDFDFD"/>
            <path d="M80.9049 0.0117188H84.1628V20.7423H81.1819V18.8613C80.4243 20.133 79.2704 20.7688 77.7222 20.7688C76.1391 20.7688 74.8642 20.1045 73.8956 18.7777C72.9271 17.4489 72.4428 15.6922 72.4428 13.5033C72.4428 11.3145 72.9271 9.55776 73.8956 8.22897C74.8642 6.90019 76.1391 6.23579 77.7222 6.23579C79.0851 6.23579 80.1473 6.73103 80.9049 7.72354V0.0117188ZM80.261 16.4503C80.7233 15.7778 80.9562 14.7954 80.9562 13.5033C80.9562 12.2133 80.7196 11.2269 80.2482 10.5441C79.7767 9.86142 79.153 9.51903 78.3789 9.51903C77.5865 9.51903 76.9554 9.86549 76.484 10.5564C76.0125 11.2493 75.7759 12.2214 75.7759 13.4748C75.7759 14.7465 76.007 15.7288 76.4711 16.4218C76.9334 17.1147 77.5699 17.4591 78.3771 17.4591C79.1695 17.4591 79.7969 17.1228 80.261 16.4503Z" fill="#FDFDFD"/>
            <path d="M96.6127 14.7388H88.9852C89.3044 16.6647 90.2895 17.6287 91.9405 17.6287C92.9842 17.6287 93.9528 17.162 94.8443 16.2266L96.4109 18.5275C95.1653 20.0234 93.6409 20.7713 91.8396 20.7713C90.0033 20.7713 88.5266 20.0988 87.4077 18.7517C86.2869 17.4045 85.7274 15.6478 85.7274 13.4773C85.7274 11.3455 86.274 9.60507 87.3691 8.25795C88.4624 6.91082 89.8529 6.23828 91.5369 6.23828C93.1365 6.23828 94.415 6.82319 95.3763 7.99301C96.3357 9.16283 96.8163 10.7015 96.8163 12.6091C96.8163 13.3163 96.7484 14.0275 96.6127 14.7388ZM88.9614 12.1567H93.7088C93.6923 11.2212 93.4813 10.4916 93.0778 9.96784C92.6742 9.44407 92.1349 9.18117 91.4617 9.18117C90.805 9.18117 90.2528 9.44407 89.807 9.96784C89.3613 10.4916 89.0788 11.2212 88.9614 12.1567Z" fill="#FDFDFD"/>
            <path d="M105.553 6.54395H109.013L103.482 20.7713H102.195L96.664 6.54395H100.124L102.851 14.3169L105.553 6.54395Z" fill="#FDFDFD"/>
            <path d="M111.541 4.30589C112.1 4.30589 112.573 4.10209 112.957 3.69245C113.34 3.28281 113.533 2.77739 113.533 2.1721C113.533 1.56681 113.34 1.05527 112.957 0.637476C112.573 0.219684 112.1 0.00976562 111.541 0.00976562C110.996 0.00976562 110.532 0.219684 110.148 0.637476C109.765 1.05527 109.573 1.56681 109.573 2.1721C109.573 2.77739 109.765 3.28485 110.148 3.69245C110.532 4.10209 110.996 4.30589 111.541 4.30589Z" fill="#FDFDFD"/>
            <path d="M113.181 6.54395H109.923V20.7713H113.181V6.54395Z" fill="#FDFDFD"/>
            <path d="M125 11.7368V20.7734H122.019V19.2C121.177 20.2475 120.039 20.7713 118.608 20.7713C117.412 20.7713 116.453 20.3922 115.73 19.6341C115.006 18.876 114.644 17.8896 114.644 16.6729C114.644 15.4195 115.039 14.429 115.831 13.6994C116.622 12.9698 117.7 12.605 119.063 12.605H121.74V11.6798C121.74 10.9685 121.567 10.4162 121.222 10.0249C120.878 9.63157 120.377 9.43592 119.72 9.43592C118.726 9.43592 117.766 10.0432 116.842 11.2599L115.024 9.12818C116.286 7.20226 117.97 6.23828 120.076 6.23828C121.608 6.23828 122.811 6.71518 123.688 7.66897C124.561 8.62072 125 9.97803 125 11.7368ZM121.742 15.6355V15.018H119.57C118.475 15.018 117.928 15.5133 117.928 16.5058C117.928 16.9929 118.075 17.3719 118.37 17.643C118.664 17.914 119.047 18.0506 119.52 18.0506C120.16 18.0506 120.691 17.8264 121.111 17.378C121.531 16.9276 121.742 16.3468 121.742 15.6355Z" fill="#FDFDFD"/>
        </svg>
    );
}

export { LogoBlack, LogoWhite };