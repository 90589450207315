import Typical from 'react-typical';

function ContactButton() {
    return (
        <button className="bg-white rounded-full py-4 px-20">
            Contact Us
        </button>
    )
}

function MainBanner({current}) {

    const styleContainer = {
        minHeight: '77vh',
        maxHeight: '800px'
    };

    const styleMessage = {
        // fontFamily: 'Graphik',
        fontStyle: 'normal',
        fontWeight: 'bold',
        // fontSize: '72px',
        lineHeight: '84px',
        color: '#FDFDFD'
    };

    const scrollToForm = () => document.getElementById('drop-us-a-line').scrollIntoView({behavior: "smooth"});

    return (
        <section className="w-full flex items-center" style={styleContainer}>
            <div className="container mx-auto lg:mt-0 xl:mt-0">
                <div className="wellcome-message sm:w-full md-w-full lg:w-1/2 text-center lg:pl-0 lg:text-left">
                    <h1 className="lg:text-left text-center sm:w-full md:w-full text-4xl md:text-4xl lg:text-6xl xl:text-6xl"  style={styleMessage}>
                        Meet your
                        <br/>
                        <span className="text-green-500">
                        <Typical
                            steps={[current]}
                            loop={1}
                            wrapper="span"
                        />
                            {/* product oriented */}
                        </span> 
                        <br/>
                        Technical Partner  
                    </h1>
                </div>

                <div onClick={scrollToForm} className="contact-us-box text-left mt-12 sm:w-full md:w-full text-center md:text-center lg:text-left xl:text-left">
                    <ContactButton></ContactButton>
                </div>
            </div>
        </section>
    );
}

export default MainBanner;