import { useEffect, useState } from "react";
import MainBanner from "./components/main-banner/main-banner.component";
import MainContent from "./components/main-content/main-content";

function HomePage() {

    const words = [
        'remote', 
        'trusted', 
        'product oriented', 
        'rockstar', 
        'experienced', 
        'agile', 
        'creative', 
        'reliable'
    ];

    const INTERVAL = 4000;
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {

        const ticker = setTimeout(() => {
            if ((words.length - 1) === currentIndex) {
                setCurrentIndex(0)
            } else {
                setCurrentIndex(currentIndex + 1);
            }

            console.log(currentIndex, words[currentIndex]);
        }, INTERVAL);
    }, [currentIndex])

    return (
        <div className="home-page">
            <MainBanner  current={words[currentIndex]} />
            <MainContent words={words} current={currentIndex}></MainContent>
        </div>
    );
}

export default HomePage;