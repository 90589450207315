import TechStack from '../../components/tech-stack/tech-stack.component';

import img1 from '../../resources/ondevia-services-1.png';
import img2 from '../../resources/ondevia-services-2.png';
import img3 from '../../resources/ondevia-services-3.png';
import { DropUsALine } from '../home/components/main-content/main-content';

function ServicesPage() {
    const scrollToForm = () => document.getElementById('drop-us-a-line').scrollIntoView({behavior: "smooth"});

    return (
        <div className="services-page w-100 bg-white">
            <div className="container mx-auto pt-32">
                <div className="lg:flex w-full pb-20 lg:pr-16">
                    <div className="lg:w-1/2">
                        <h2 className="text-6xl px-8 lg:px-0 font-bold text-center lg:text-left">
                        Our services
                        </h2>
                    </div>
                    <div className="lg:w-1/2">
                        <p className="text-xl px-8 lg:px-0 lg:pl-24 pt-4">
                            Success for us means creating products for clients that have value. Here, at Ondevia, we turn your ideas into products and make them work for you.
                        </p>
                    </div>
                </div>

                <div className="lg:flex lg:px-0 px-4 w-full pt-24">
                    <div className="lg:w-1/2 lg:pr-12">
                        <img className="w-full" src={img1}></img>
                    </div>
                    <div className="lg:w-1/2 ">
                        <div className="w-full lg:px-24 pt-10">
                            <h3 className="text-3xl text-center lg:text-left font-bold w-full">
                                Software Engineering  
                            </h3>
                            <p className="w-full text-lg pt-10">
                                Build software of any size <br/> withour top-level engineers.
                            </p>
                            <ul className="list-disc pl-4 pt-10 text-lg">
                                <li className="pt-6"> All types of web development </li>
                                <li className="pt-6"> Mobile apps development </li>
                                <li className="pt-6"> Development of scalable infrastructures </li>
                                <li className="pt-6"> Desktop applications and software creation </li>
                                <li className="pt-6"> Support and enhancement of existing solutions </li>
                                <li className="pt-6"> Cloud implementation </li>
                                <li className="pt-6"> Devops architecture, strategy and support </li>
                                <li className="pt-6"> Site reliability engineering </li>
                                <li className="pt-6"> Quality assurance </li>
                            </ul>
                            <div className="w-full pt-16">
                                <button onClick={scrollToForm} className="rounded-full border-2 border-black text-bold px-24 py-4">
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="flex lg:flex-row lg:flex-wrap flex-col flex-wrap-reverse  w-full lg:pt-52">
                    <div className="lg:hidden w-full pt-24 px-4">
                        <img className="w-full" src={img2}></img>
                    </div>
                    <div className="lg:w-1/2 w-full">
                        <div className="w-full px-4 lg:px-24 pt-10">
                            <h3 className="text-3xl font-bold w-full">
                                Product Management
                            </h3>
                            <p className="w-full text-lg pt-10">
                                Develop a full understanding of the "what" and "why" thanks to our product management expertise.
                            </p>
                            <ul className="list-disc pl-4 pt-10 text-lg">
                                <li className="pt-6"> Strategy negotiation </li>
                                <li className="pt-6"> Roadmap development </li> 
                                <li className="pt-6"> Requirements management <br/> and Business analysis </li>
                                <li className="pt-6"> Discovery activities, market <br/> research and ideation </li>
                                <li className="pt-6"> User journey and funnels <br/> improvements </li>
                                <li className="pt-6"> Hypothesis testing </li>
                            </ul>
                            <div className="w-full pt-16">
                                <button onClick={scrollToForm} className="rounded-full border-2 border-black text-bold px-24 py-4">
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 hidden lg:flex w-full">
                        <img className="w-full" src={img2}></img>
                    </div>
                </div>

                <div className="lg:flex w-full pt-24 lg:pt-52">
                    <div className="lg:w-1/2 px-4 lg:px-0">
                        <img className="w-full" src={img3}></img>
                    </div>
                    <div className="lg:w-1/2">
                        <div className="w-full px-4 lg:px-24 pt-10">
                            <h3 className="text-2xl font-bold w-full">
                                Design
                            </h3>
                            <p className="w-full text-lg pt-10">
                                Deliver beautiful and meaningful <br/> experiences for users.
                            </p>
                            <ul className="list-disc pl-4 pt-10 text-lg">
                                <li className="pt-6"> Ideation </li>
                                <li className="pt-6"> Design research </li> 
                                <li className="pt-6"> UX analysis and development </li>
                                <li className="pt-6"> Design systems </li>
                                <li className="pt-6"> Prototyping </li>
                                <li className="pt-6"> Design testing </li>
                            </ul>
                            <div className="w-full pt-16">
                                <button onClick={scrollToForm} className="rounded-full border-2 border-black text-bold px-24 py-4">
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:flex w-full pt-24 lg:pt-52">
                    <div className="lg:w-1/2 px-4 lg:px-0">
                        <h3 className="font-bold text-3xl"> The technologies we work with: </h3>
                        <p className="w-full pt-12 pb-12 lg:pr-32 text-lg">
                            We work with a huge variety of technologies. 
                            The right tech experts will be matched to the right tasks. 
                            Our experts will take care of your techstack. 
                            Or, we will form a dedicated team up to your needs in the shortest time. 
                            And here you see the most popular technologies we work with.
                        </p>
                        <button onClick={scrollToForm} className="rounded-full bg-black px-24 py-4 text-white">
                            Contact us
                        </button>
                    </div>
                    <div className="lg:w-1/2 pt-12 lg:pt-0 px-4 lg:px-0">
                        <TechStack />
                    </div>
                </div>

                <div className="lg:flex w-full pt-60">
                    <div className="lg:w-1/2 pl-4 pr-4 xl:pl-24 xl:pr-56">
                        <h2 className="text-6xl text-center lg:text-left font-bold"> How to start?  </h2>
                        <p className="w-full text-xl pt-12 pb-12">
                            It takes us up to 2 weeks to start your project of any complexity. Here is a process model we follow: 
                        </p>
                        <button onClick={scrollToForm} className="rounded-full bg-black px-8 xl:px-24 py-4 text-white">
                            Contact us
                        </button>
                    </div>
                    <div className="lg:w-1/2 pt-24 lg:pt-0 xl:pr-24">
                        <div className="w-full lg:flex pl-4 pr-4 lg:pr-0 xl:pl-16 lg:pb-12">
                            <div className="lg:w-1/2 lg:pb-0 pb-8 lg:pr-6">
                                <div className="square rounded-lg bg-gray-100">
                                    <div className="p-6">
                                        <div className="w-full">
                                            <button className="rounded-full text-white bg-black px-6 py-2 cursor-default">
                                                Step 1
                                            </button>
                                            <p className="pt-6 font-bold text-sm xl:text-md">
                                                You contact us and tell us about your project or idea.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-1/2 lg:pb-0 pb-8 lg:pl-6">
                                <div className="square rounded-lg bg-gray-100">
                                    <div className="p-6">
                                        <div className="w-full">
                                            <button className="rounded-full text-white bg-black px-6 py-2 cursor-default">
                                                Step 2
                                            </button>
                                            <p className="pt-6 font-bold text-sm xl:text-md">
                                                We analyze the list of your requirements and comeback to you with a proposal and estimates of the work.
                                            </p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:flex pl-4 pr-4 lg:pr-0 xl:pl-16">
                            <div className="lg:w-1/2 lg:pb-0 pb-8 lg:pr-6">
                                <div className="square rounded-lg bg-gray-100">
                                    <div className="p-6">
                                        <div className="w-full">
                                            <button className="rounded-full text-white bg-black px-6 py-2 cursor-default">
                                                Step 3
                                            </button>
                                            <p className="pt-6 font-bold text-sm xl:text-md">
                                                After agreeing on a proposal and evaluation of the work, we conduct a detailed decomposition and createa roadmap.
                                            </p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            <div className="lg:w-1/2 lg:pb-0 pb-8 lg:pl-6">
                                <div className="square rounded-lg bg-gray-100">
                                    <div className="p-6">
                                        <div className="w-full">
                                            <button className="rounded-full text-white bg-black px-6 py-2 cursor-default">
                                                Step 4
                                            </button>
                                            <p className="pt-6 font-bold text-sm xl:text-md">
                                                We need 7-14 days to complete the team for your project and start the project. 
                                            </p>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full pt-8 lg:pt-24">
                    <DropUsALine/>
                </div>
            </div>
        </div>
    );
}

export default ServicesPage;