import './App.css';
import mainBannerImg from './resources/main-banner.jpg'
import Header from './components/header/Header';
import Footer from './components/footer/footer';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from './pages/home/home.page';
import ServicesPage from './pages/services/services.page';
import WorksPage from './pages/works/works.page';
import AboutUsPage from './pages/about-us/about-us.page';

function App() {

  const style = {
    backgroundColor: '#262626',
    backgroundImage: `url(${mainBannerImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    overflow: 'auto'
  };

  return (
    <div className="App" style={style}>
      <Router>
        <Header></Header>
        <main id="main" className="relative">
          <Switch>
            <Route path="/works">
              <WorksPage></WorksPage>
            </Route>
            <Route path="/services">
              <ServicesPage></ServicesPage>
            </Route>
            <Route path="/about-us">
              <AboutUsPage></AboutUsPage>
            </Route>
            <Route path="/">
              <HomePage></HomePage>
            </Route>
          </Switch>


          {/* <MainContent></MainContent> */}
        </main>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
