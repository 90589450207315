import { useState, useEffect } from 'react';
import useLocationChange from '../../hooks/use-location-change.hooks';
import { LogoWhite, LogoBlack } from '../logo/logo';


function GetInTouchButton({style}) {
    return (
        <button style={style} className="rounded-full py-4 px-12 border-2 border-white text-white">
            Get in Touch
        </button>
    );
}


function Header() {
    const [headStyle, setHeadStyle] = useState('white');
    const [navOpened, setNavOpened] = useState(false);

    // const listenScrollEvent = ({srcElement}) => setHeadStyle(!!srcElement.scrollTop ? "white" : "transparent");

    const isTransparent = () => headStyle === 'transparent';

    useLocationChange(location => setHeadStyle(
        (location.pathname === '/') ? 'transparent' : 'white'));
    
    // useEffect(() => {
    //     window.addEventListener('scroll', listenScrollEvent, true);
    //     return () => window.removeEventListener('scroll', listenScrollEvent); }, []);

    const onNavClick = () => setNavOpened(!navOpened);
    const scrollToForm = () => {
        document.getElementById('drop-us-a-line').scrollIntoView({behavior: "smooth"});
        setNavOpened(false);
    };

    return (
<header role="banner"  
    className=" w-100 top-0 z-50 px-0 mx-auto w-100 flex flex-col justify-center"
    style={ isTransparent() ? {} : { backgroundColor: 'white'} }>
        
    <div className="flex p-4 flex-wrap lg:py-0 lg:px-4 lg:container lg:mx-auto justify-between ">
        <div className="my-2 pr-2 lg:w-2/3 overflow-hidden flex items-center">
            <div className="ml-4 lg:ml-0">
                <a href="/">
                    { isTransparent() ? <LogoWhite/> : <LogoBlack/> }
                </a>
            </div>
        </div>

        {/* <div className="w-5/12 lg:hidden"></div> */}
        <div className="my-2 px-2 w-2/12 lg:hidden flex flex-row">
            <div className="self-centerr block mr-4 w-full">
                <button onClick={onNavClick}  className="text-first w-full" aria-haspopup="true" aria-expanded="false" aria-controls="menu" aria-label="Navigation">
                    <div className={ (navOpened ? "hidden " : "") + "open w-100 align-end" }>
                        <div className={(isTransparent() ? "bg-white" : "bg-black") + " w-100 h-1 rounded-full mb-2"}></div>
                        <div className={(isTransparent() ? "bg-white" : "bg-black") + " w-100 h-1 rounded-full mb-2"}></div>
                        <div className={(isTransparent() ? "bg-white" : "bg-black") + " w-100 h-1 rounded-full mb-2"}></div>
                    </div>
                </button>
            </div>
        </div>
        {/* <div className="lg:w-1/3 hidden lg:block"></div> */}
        <div className={(navOpened ? " fixed top-0 bottom-0 left-0 right-0 bg-black z-50 " : "hidden ") + "w-full lg:w-1/3 lg:block lg:mt-4 lg:mt-0 lg:text-right"} id="nav-content" aria-expanded="false">
            <div className="px-6 lg:px-0 lg:pt-5 xl:pt-0">
                <div className="w-full flex justify-between">
                    <div className="my-2 pr-2 lg:w-2/3 lg:hidden overflow-hidden flex items-center">
                        <div className="ml-4 lg:ml-0">
                            <a href="/">
                                <LogoWhite/>
                            </a>
                        </div>
                    </div>
                    <div onClick={onNavClick} className={ (navOpened ? "" : "hidden ") + "close w-100 my-10" }>
                        <span className="text-white  px-10">close</span>
                    </div>
                </div>

                <div>
                    <nav className="lg:hidden xl:hidden" role="navigation" aria-labelledby="block-main-menu" id="block-main">
                        <ul className="list-reset lg:flex justify-end flex-1 items-center py-4 w-full">
                            <li className="py-8 w-full text-center  inline-block w-full text-2xl lg:text-base font-semibold no-underline lg:px-8 whitespace-nowrap">
                                <a href="/works" className="text-white no-underline  text-white is-active"
                                    
                                >Our Works</a>
                            </li>
                            <li className="py-8 w-full text-center  inline-block w-full text-2xl lg:text-base font-semibold no-underline lg:px-8 whitespace-nowrap">
                                <a href="/services" className="text-white no-underline text-white"
                                    
                                >Services</a>
                            </li>
                            <li className="py-8 w-full text-center inline-block w-full text-2xl lg:text-base font-semibold no-underline lg:px-8 whitespace-nowrap">
                                <a href="/about-us" className="text-white no-underline is-active text-white"
                                    
                                >About</a>
                            </li>
                            <li onClick={scrollToForm} className="py-8 w-full text-center inline-block w-full text-2xl lg:text-base font-semibold no-underline whitespace-nowrap">
                                <GetInTouchButton  />
                            </li>
                        </ul>
                    </nav>
                    <nav className="hidden xl:block lg:block" role="navigation" aria-labelledby="block-main-menu" id="block-main">
                        <ul className="list-reset lg:flex justify-end flex-1 items-center py-4">
                            <li className="inline-block w-full text-xl lg:text-base font-semibold no-underline lg:px-8 whitespace-nowrap">
                                <a href="/works" className="text-white no-underline  text-white is-active"
                                    style={ isTransparent() ? {} : { color: 'black'} }
                                >Our Works</a>
                            </li>
                            <li className="inline-block w-full text-xl lg:text-base font-semibold no-underline lg:px-8 whitespace-nowrap">
                                <a href="/services" className="text-white no-underline text-white"
                                    style={ isTransparent() ? {} : { color: 'black'} }
                                >Services</a>
                            </li>
                            <li className="inline-block w-full text-xl lg:text-base font-semibold no-underline lg:px-8 whitespace-nowrap">
                                <a href="/about-us" className="text-white no-underline is-active text-white"
                                    style={ isTransparent() ? {} : { color: 'black'} }
                                >About</a>
                            </li>
                            <li onClick={scrollToForm} className="inline-block w-full text-xl lg:text-base font-semibold no-underline whitespace-nowrap pl-12">
                                <GetInTouchButton  style={ isTransparent() ? {} : { color: 'black', borderColor: 'black'} }/>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>

    );
}

export default Header;