import bannerImg from '../../resources/banner-image.png';
import aboutUsImg1 from '../../resources/about-ondevia-1.png';
import { DropUsALine } from '../home/components/main-content/main-content';

function AboutUsPage() {
    const scrollToForm = () => document.getElementById('drop-us-a-line').scrollIntoView({behavior: "smooth"});

    return (
        <div className="about-us-page w-100 bg-white">
            <div className="container mx-auto pt-32">
                <div className="lg:flex w-full">
                    <div className="lg:w-1/2">
                        <h2 className="text-4xl px-8 lg:px-0 font-bold text-center lg:text-left">
                            Ondevia Family
                        </h2>
                    </div>
                    <div className="lg:w-1/2">
                        <p className="text-lg pt-4 px-8 lg:px-0 lg:pl-24 lg:pt-0">
                            Our company's main credo is that there are great people behind every great product. 
                            That is why we have gathered a team of highly qualified and motivated professionals 
                            who are fully dedicated to what they do. 
                        </p>
                    </div>
                </div>
                <div className="image-box w-full h-80 lg:h-auto pt-16 px-4 lg:px-0">
                    <img src={bannerImg} className="w-full h-full lg:object-cover"></img>
                </div>

                <div className="w-full pt-36">
                    <div className="text-center w-full">
                        <h2 className="text-4xl font-bold">
                            Main pillars of our company:
                        </h2>
                    </div>
                    <div className="lg:flex px-4 lg:px-0 w-full pt-24">
                        <div className="lg:w-1/2">
                            <img src={aboutUsImg1}></img>
                        </div>
                        <div className="lg:w-1/2 md:pl-12 lg:pr-24 xl:pr-80">
                            <div className="w-full">
                                <h3 className="text-2xl pt-24 lg:pt-0 font-bold w-full">
                                    We love what we do
                                </h3>
                                <p className="w-full text-md xl:text-lg pt-10 ">
                                    Our company was founded by professional engineers and product managers 
                                    who knows how to create truly outstanding it products©and remote services
                                </p>
                            </div>
                            <div className="w-full pt-16">
                                <h3 className="text-2xl font-bold w-full">
                                    Clients success is our success
                                </h3>
                                <p className="w-full text-md xl:text-lg pt-10">
                                    We don’t just deliver projects and close tasks - we drive 
                                    businesses of our clients and make their products successful
                                </p>
                            </div>
                            <div className="w-full pt-16">
                                <h3 className="text-2xl font-bold w-full">
                                    Company’s culture over numbers
                                </h3>
                                <p className="w-full text-md xl:text-lg pt-10">
                                    We truly appreciate our teammates and we make everything 
                                    to hire the top specialists and the most the right people
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full pt-36">
                    <div className="text-center w-full">
                        <h2 className="text-4xl font-bold lg:px-96 pb-16">
                            Join our warm family today and make your product outstanding success
                        </h2>
                        <button onClick={scrollToForm} className="rounded-full bg-black text-white px-24 py-4">
                            Get in touch
                        </button>
                    </div>
                </div>

                <div className="w-full">
                    <DropUsALine />
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;