import an from '../../resources/AN.svg';
import angular from '../../resources/ANGULAR.svg';
import ap from '../../resources/AP.svg';
import aws from '../../resources/AWS.svg';
import js from '../../resources/JS.svg';
import net from '../../resources/NET.svg';
import react from '../../resources/REACT.svg';
import ts from '../../resources/TS.svg';


function TechStack() {
    return (
<div className="w-full">
    <div className="w-full flex justify-around">
        <div className="lg:p-8 xl:p-8 p-2"> <img src={js}></img> </div>
        <div className="lg:p-8 xl:p-8 p-2"> <img src={net}></img> </div>
        <div className="lg:p-8 xl:p-8 p-2"> <img src={react}></img> </div>
        <div className="lg:p-8 xl:p-8 p-2"> <img src={ts}></img> </div>
    </div>
    <div className="w-full flex justify-around">
        <div className="lg:p-8 xl:p-8 p-2"> <img src={an}></img> </div>
        <div className="lg:p-8 xl:p-8 p-2"> <img src={angular}></img> </div>
        <div className="lg:p-8 xl:p-8 p-2"> <img src={ap}></img> </div>
        <div className="lg:p-8 xl:p-8 p-2"> <img src={aws}></img> </div>
    </div>
</div>
    );
}

export default TechStack;