import { LogoWhite, LogoBlack } from '../logo/logo';

function Footer() {

    const scrollToForm = () => document.getElementById('drop-us-a-line').scrollIntoView({behavior: "smooth"});

    return (
        <footer className="w-full bg-gray-900">
            <div className="container mx-auto">
                <div className="py-10 px-4 lg:px-0">
                        <a href="/"><LogoWhite/></a>
                </div>
            </div>
                
            <div className="container lg:flex mx-auto">
                <div className="lg:w-1/3 px-4 lg:px-0">
                    <div className="text-2xl font-bold">
                        <span className="text-white"> We are your </span>
                        <br/>
                        <span className="text-green-500">product oriented</span>
                        <br/>
                        <span className="text-white"> Technical Partner </span>  
                    </div>
                    <div className="py-10">
                        <button onClick={scrollToForm} className="bg-white rounded-full py-2 px-14 text-base">
                            Contact Us
                        </button>
                    </div>
                </div>
                <div className="lg:w-1/3 px-4 lg:px-0">
                    <div className="mb-6">
                        <a className="text-white text-base" href="/about-us">
                            About
                        </a>
                    </div>
                    <div className="mb-6">
                        <a className="text-white text-base" href="/services">
                            Services
                        </a>
                    </div>
                    <div className="mb-6">
                        <a className="text-white text-base" href="/works">
                            Our Works
                        </a>
                    </div>
                    {/* <div className="mb-6">
                        <a className="text-white text-base" href="#">
                            How To Start
                        </a>
                    </div> */}
                </div>
                <div className="lg:w-1/3 px-4 lg:px-0">
                    <div className="mb-6">
                        <p className="text-white text-base">
                            Social Links:
                        </p>
                        <div className="w-100 flex pt-4">
                            {/* <a href="#" className="pr-4">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#FDFDFD"/>
                                    <mask id="linkedIn" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20" height="20">
                                    <path d="M10.5424 11.4875C11.4822 11.4875 12.2441 10.7256 12.2441 9.78576C12.2441 8.8459 11.4822 8.08398 10.5424 8.08398C9.60249 8.08398 8.84058 8.8459 8.84058 9.78576C8.84058 10.7256 9.60249 11.4875 10.5424 11.4875Z" fill="#3C8CBC"/>
                                    <path d="M13.8515 12.7763V22.2177H16.7829V17.5487C16.7829 16.3167 17.0147 15.1236 18.5422 15.1236C20.0488 15.1236 20.0675 16.5322 20.0675 17.6265V22.2185H23.0005V17.0409C23.0005 14.4975 22.4529 12.543 19.4802 12.543C18.053 12.543 17.0963 13.3262 16.7051 14.0674H16.6655V12.7763H13.8515ZM9.07434 12.7763H12.0105V22.2177H9.07434V12.7763Z" fill="#3C8CBC"/>
                                    </mask>
                                    <g mask="url(#linkedIn)">
                                    <rect x="6.92578" y="6.92578" width="18.1481" height="18.1481" fill="#262626"/>
                                    </g>
                                </svg>
                            </a> */}
                            <a href="#" className="">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#FDFDFD"/>
                                    <mask id="facebook" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20" height="20">
                                    <path d="M17.0862 22.9978V16.6231H19.2368L19.5565 14.1272H17.0862V12.5374C17.0862 11.8172 17.2869 11.3241 18.3206 11.3241H19.6304V9.09889C18.9934 9.03045 18.3525 8.99778 17.7116 9.00012C15.8107 9.00012 14.5056 10.1606 14.5056 12.2909V14.1226H12.369V16.6185H14.5102V22.9978H17.0862Z" fill="url(#paint0_linear)"/>
                                    </mask>
                                    <g mask="url(#facebook)">
                                    <rect x="6.6665" y="6.66699" width="18.1482" height="18.1481" fill="#262626"/>
                                    </g>
                                    <defs>
                                    <linearGradient id="paint0_linear" x1="15.9997" y1="9" x2="11.9495" y2="22.0243" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#00B1FF"/>
                                    <stop offset="1" stop-color="#006BFF"/>
                                    </linearGradient>
                                    </defs>
                                </svg>
                            </a>
                            <a href="#" className="px-4">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#FDFDFD"/>
                                    <mask id="maks1010" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20" height="20">
                                    <ellipse cx="15.9997" cy="16.0009" rx="2.12852" ry="2.01649" fill="#FC3F38"/>
                                    <path d="M18.4156 18.666C17.694 19.2619 16.7833 19.5839 15.8212 19.5678C13.6219 19.5678 12.0068 18.0541 12.0068 15.9766C12.0068 13.8992 13.5704 12.4499 15.8212 12.4499C16.7833 12.4338 17.7112 12.7558 18.4328 13.3356L18.9139 13.7221L21.0444 11.7252L20.5118 11.2582C19.2232 10.1792 17.5565 9.59946 15.8212 9.61556C11.8694 9.61556 9 12.2888 9 15.9605C9 19.6322 11.9381 22.386 15.8212 22.386C17.5737 22.4021 19.2575 21.8063 20.5462 20.7112L21.0616 20.2603L18.8967 18.2634L18.4156 18.666Z" fill="#19313B"/>
                                    </mask>
                                    <g mask="url(#maks1010)">
                                    <rect x="6.66687" y="6.66699" width="18.6667" height="18.6667" fill="#262626"/>
                                    </g>
                                </svg>
                            </a>
                            <a href="#" className="px-4"> 
                                {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#FDFDFD"/>
                                    <mask id="mask2020" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20" height="20">
                                    <path d="M20.9097 12.009C18.9837 12.0182 17.5198 13.292 16.9718 15.3219C16.0523 13.9069 15.3349 12.2883 14.9236 10.9219L12.8965 10.9314L12.9246 16.3046C12.9258 16.5576 12.8772 16.8083 12.7815 17.0424C12.6858 17.2766 12.5449 17.4896 12.3669 17.6694C12.1888 17.8491 11.9772 17.992 11.7439 18.0899C11.5107 18.1878 11.2604 18.2388 11.0074 18.24C10.4966 18.2424 10.0056 18.0418 9.64268 17.6823C9.46296 17.5042 9.32006 17.2926 9.22215 17.0593C9.12424 16.8261 9.07323 16.5758 9.07203 16.3228L9.04395 10.9497L7.01575 10.9595L7.04382 16.3329C7.05168 18.5627 8.83338 20.3296 11.0138 20.3183C13.1941 20.3071 14.9598 18.5254 14.95 16.295L14.9458 15.3826C15.3546 16.1912 15.8656 17.0503 16.4271 17.8083L15.1875 23.7447L17.2651 23.7352L18.158 19.4221C18.971 19.9255 19.8846 20.2251 20.9487 20.2201C23.1791 20.2102 24.9956 18.3774 24.9843 16.0452C24.9742 13.8143 23.1403 11.9986 20.91 12.0087L20.9097 12.009ZM20.9386 18.1424C20.1278 18.1461 19.3152 17.7954 18.6538 17.2404L18.8526 16.4262V16.3751C19.0008 15.512 19.4495 14.0403 20.97 14.0335C21.5074 14.0326 22.0232 14.2442 22.405 14.6224C22.7868 15.0005 23.0034 15.5143 23.0075 16.0516C22.9618 17.2176 22.0032 18.1349 20.9383 18.1396L20.9386 18.1424Z" fill="#6FDA44"/>
                                    </mask>
                                    <g mask="url(#mask2020)">
                                    <rect x="6.92578" y="6.66699" width="18.1481" height="18.1481" fill="#262626"/>
                                    </g>
                                </svg> */}
                            </a>
                        </div>

                        <div className="w-100 pt-10 text-sm">
                            <p className="text-white">
                             Kyiv Office
                            </p>
                            <p className="text-gray-700 pt-4">
                                Sportyvna Square, 1a
                            </p>
                            <p className="text-white pt-4">
                             Baku Office
                            </p>
                            <p className="text-gray-700 pt-4">
                                Bridge Plaza, Bakikhanov str. 24, Baku, Azerbaijan
                            </p>
                            <p className="text-white pt-4">
                                contact@ondevia.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container flex mx-auto">
                <div className="w-1/3 text-sm text-gray-700">
                © 2021 Ondevia. All rights reserved.
                </div>
                {/* <div className="w-1/3 text-sm text-gray-700">
                Privacy Poly
                </div>
                <div className="w-1/3 text-sm text-gray-700">
                Coockie Policy
                </div> */}
            </div>
        </footer>
    );
}

export default Footer;