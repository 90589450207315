import { DropUsALine } from '../home/components/main-content/main-content';

import img1 from '../../resources/traizi.png';
import img2 from '../../resources/quatrix.png';
import img3 from '../../resources/elements.png';
import img4 from '../../resources/preenster.png';
import img5 from '../../resources/spectraa.png';


function WorksPage() {
    const scrollToForm = () => document.getElementById('drop-us-a-line').scrollIntoView({behavior: "smooth"});
    
    return (
        <div className="works-page w-full bg-white">
            <div className="container mx-auto">

                <div className="text-center xl:px-36 lg:px-36 w-full">
                    <h1 className="font-bold text-3xl xl:text-6xl lg:text-6xl xl:pt-48 lg:pt-48 pt-24">
                        Join a bench of satisfied customers who have found their long-term technical partner!
                    </h1>
                    <p className="pt-16 text-lg xl:text-2xl lg:text-2xl">
                        We work with a huge variety of technologies. 
                        The right tech experts will be matched to the right tasks. 
                        Our experts will take care of your tech stack. 
                        Or, we will form a dedicated team up to your needs in the shortest time. 
                        And here you see the most popular technologies we work with.
                    </p>
                </div>

                <div className="w-full lg:flex xl:flex pt-48">
                    <div className="lg:w-1/2 xl:w-1/2 lg:pr-6 pb-24 lg:pb-0">
                        <div className="w-full img-works-box rounded-lg px-4 lg:px-0">
                            <a target="_blank" rel="noreferrer" href="https://traizi.com/"><img className="w-full rounded-lg" src={img1} /></a>
                        </div>
                        <div className="w-full flex p-6">
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    Client
                                </div>
                                <div className="font-bold pt-4">
                                    <a target="_blank" rel="noreferrer" href="https://traizi.com/">Traizi</a>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    About
                                </div>
                                <div className="pt-4">
                                    A live streaming platform, which connects people who want to do physical activities with professional coaches.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 lg:pl-6 pb-24 lg:pb-0">
                        <div className="w-full img-works-box rounded-lg px-4 lg:px-0">
                            <a target="_blank" rel="noreferrer" href="https://www.quatrix.it/"><img className="w-full rounded-lg" src={img2} /></a>
                        </div>
                        <div className="w-full flex p-6">
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    Client
                                </div>
                                <div className="font-bold pt-4">
                                    <a target="_blank" rel="noreferrer" href="https://www.quatrix.it/"> Quatrix </a>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    About
                                </div>
                                <div className="pt-4">
                                    Easy-to-use, centralised, secure file sharing platform.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:flex xl:flex lg:pt-24">
                    <div className="lg:w-1/2 xl:w-1/2 lg:pr-6 lg:pb-0 pb-24">
                        <div className="w-full img-works-box rounded-lg px-4 lg:px-0">
                            <a target="_blank" rel="noreferrer" href="https://elements.cloud"><img className="w-full rounded-lg" src={img3} /></a>
                        </div>
                        <div className="w-full flex p-6">
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    Client
                                </div>
                                <div className="font-bold pt-4">
                                    <a target="_blank" rel="noreferrer" href="https://elements.cloud">Elements.cloud</a>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    About
                                </div>
                                <div className="pt-4">
                                    The management platform for Salesforce.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 lg:pl-6 lg:pb-0 pb-24">
                        <div className="w-full img-works-box  rounded-lg px-4 lg:px-0">
                            <a target="_blank" rel="noreferrer" href="http://preenster.com"><img className="w-full rounded-lg" src={img4} /></a>
                        </div>
                        <div className="w-full flex p-6">
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    Client
                                </div>
                                <div className="font-bold pt-4">
                                    <a target="_blank" rel="noreferrer" href="http://preenster.com">Presenster</a>
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    About
                                </div>
                                <div className="pt-4">
                                    The mobile service for clothing recommendations.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:flex xl:flex lg:pt-24">
                    <div className="lg:w-1/2 xl:w-1/2 lg:pr-6 lg:pb-0 pb-24">
                        <div className="w-full img-works-box rounded-lg px-4 lg:px-0">
                            <a target="_blank" rel="noreferrer" href="https://apps.apple.com/ru/app/%D1%81%D0%BF%D0%B5%D0%BA%D1%82%D1%80%D0%B0/id1419494347"><img className="w-full" src={img5} /></a>
                        </div>
                        <div className="w-full flex p-6">
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    Client
                                </div>
                                <div className="font-bold pt-4">
                                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/ru/app/%D1%81%D0%BF%D0%B5%D0%BA%D1%82%D1%80%D0%B0/id1419494347"></a>Spectra
                                </div>
                            </div>
                            <div className="w-1/2">
                                <div className="text-gray-300 font-bold">
                                    About
                                </div>
                                <div className="pt-4">
                                    Mobile applications for medical clinic.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 xl:w-1/2 lg:pl-6 lg:pb-0 pb-0 lg:pb-24 px-4 lg:px-0">
                        <div className="w-full flex flex-col justify-center img-works-box bg-gray-800 rounded-lg px-4 lg:px-0 text-center lg:p-24 ">
                            <h4 className="text-white lg:text-4xl font-bold">
                                Like what you see? <br className="hidden lg:block"/>We’d love to work with you.
                            </h4>
                            <button onClick={scrollToForm} className="rounded-full text-white border-2  lg:mx-32 border-white mt-12 px-12 py-4">
                                Start a project
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <DropUsALine />
                </div>

            </div>
        </div>
    );
}

export default WorksPage;